import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Container, Grid, IconButton } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import PersonIcon from "@mui/icons-material/Person";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { withRouter, Link } from "react-router-dom";

import GlobalBanner from "../../components/GlobalBanner/GlobalBanner";

import InstantPriceProgress from "../InstantPriceProgress/InstantPriceProgress";
import { ReactComponent as Logo } from "../../assets/images/monarchy-logo.svg";

import { logout } from "../../slices/authSlice";
import { RootState } from "../../rootReducer";

import "./Navigation.scss";
import CTA from "../CTA/CTA";

const Navigation = (props: any) => {
  const dispatch = useDispatch();
  const step = useSelector((state: RootState) => state.instantPrice.step);
  const progressBarVisible = useSelector(
    (state: RootState) => state.instantPrice.progressBarVisible
  );

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const user = useSelector((state: RootState) => state.auth.user);

  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);

  useEffect(() => {
    let lastknownScrollPosition = 0;
    let ticking = false;
    const mainNavigationEl = document.querySelector(".main-navigation");

    window.addEventListener("scroll", () => {
      lastknownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (lastknownScrollPosition > 0) {
            mainNavigationEl?.classList.add("scrolled");
          } else {
            mainNavigationEl?.classList.remove("scrolled");
          }

          ticking = false;
        });

        ticking = true;
      }
    });
  }, []);

  const renderSignInLink = () => {
    if (isAuthenticated) {
      return (
        <a
          style={{ marginLeft: "8px" }}
          onClick={() => {
            dispatch(logout());
            handleNavigate("/");
          }}
        >
          Sign out
        </a>
      );
    } else {
      return (
        <a
          onClick={() => {
            handleNavigate("/sign-in");
          }}
          className="sign-in-desktop"
        >
          <PersonIcon style={{ color: "#9b9b9b", marginRight: "8px" }} />
          <span className="text">Sign in</span>
        </a>
      );
    }
  };

  const toggleMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);
    document.body.classList.toggle("mobile-nav-open");
  };

  const handleNavigate = (url: string) => {
    props.history.push(url);
    if (mobileNavOpen) {
      toggleMobileNav();
    }
  };

  // let classNames = '';

  // if (props.instantPrice) {
  // }

  return (
    <>
      <GlobalBanner />

      <div style={{ position: "relative" }}></div>

      <nav
        className={`main-navigation ${
          props.instantPrice
            ? "main-navigation--instant-price"
            : props.landing
            ? "main-navigation--landing"
            : ""
        }`}
      >
        <Container maxWidth="xl" className="container">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <div className="main-navigation__left">
              <div className="main-navigation__left__items">
                <Link to="/about-us">About us</Link>
                <Link to="/how-we-work">How we work</Link>
                <Link to="/financing">Financing</Link>
              </div>
              <Logo
                className="mobile-logo"
                onClick={() => handleNavigate("/")}
              />
            </div>
            <div className="main-navigation__middle">
              <img
                width="150px"
                src="/images/monarchy-brand-logo.svg"
                onClick={() => handleNavigate("/")}
              />
            </div>
            <div className="main-navigation__right">
              <div className="main-navigation__right__items">
                <a
                  href="tel:6135050552"
                  className="mobile-navigation__phone-link"
                >
                  <CallIcon
                    style={{
                      marginRight: "8px",
                    }}
                  />
                  <span className="text">(613) 505-0552</span>
                </a>

                <div className="mobile-navigation__signin-link-container no-margin-bottom desktop-nav">
                  {renderSignInLink()}
                </div>

                {props.landing && (
                  <Link
                    to="/instant-price"
                    className="btn-link btn-link--landing"
                  >
                    <Button className="btn btn-primary" variant="contained">
                      Get a free estimate
                      <ArrowForwardIcon
                        style={{ marginLeft: "8px", fontSize: "18px" }}
                      />
                    </Button>
                  </Link>
                )}

                {props.location.pathname !== "/instant-price" &&
                  props.location.pathname !== "/" && (
                    <Link to="/instant-price" className="btn-link">
                      <Button className="btn btn-primary" variant="contained">
                        Get a free estimate
                        <ArrowForwardIcon
                          style={{ marginLeft: "8px", fontSize: "18px" }}
                        />
                      </Button>
                    </Link>
                  )}
              </div>
              <Box className="main-navigation__burger">
                <IconButton onClick={toggleMobileNav} size="medium">
                  {mobileNavOpen ? <MenuOpenIcon /> : <MenuIcon />}
                </IconButton>
              </Box>
            </div>
          </Grid>
          {props.location.pathname === "/instant-price" &&
            progressBarVisible && <InstantPriceProgress step={step} />}
        </Container>
      </nav>
      <div className="mobile-navigation">
        <div></div>
        <nav>
          <a onClick={() => handleNavigate("/about-us")} className="nav-link">
            About us
          </a>

          <a
            onClick={() => handleNavigate("/how-we-work")}
            className="nav-link"
          >
            How we work
          </a>
          <a onClick={() => handleNavigate("/financing")} className="nav-link">
            Financing
          </a>

          <CTA closeMenu />
        </nav>

        <div className="mobile-navigation__signin-link-container">
          {renderSignInLink()}
        </div>
      </div>
    </>
  );
};

export default withRouter(Navigation);
